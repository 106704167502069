// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as SuspiciousEmail from "../../../models/SuspiciousEmail.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

var Req = {
  SortDirection: SortDirection,
  SortBy: SortBy
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              suspiciousEmails: field.required("suspiciousEmails", Json_Decode$JsonCombinators.array(SuspiciousEmail.Dashboard.decoder)),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              totalSuspiciousEmails: field.required("totalSuspiciousEmails", Json_Decode$JsonCombinators.$$int)
            };
    });

var Res = {
  decoder: decoder
};

function exec(pageNum, sortBy, query) {
  var page = "page=" + String(pageNum);
  var query$1 = Belt_Option.getWithDefault(Belt_Option.map(query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var variant = sortBy.NAME;
  var sortBy$1 = variant === "Email" ? "&sort_by=suspicious_emails.email&sort_direction=" + toString(sortBy.VAL) : (
      variant === "IsValid" ? "&sort_by=suspicious_emails.is_valid&sort_direction=" + toString(sortBy.VAL) : (
          variant === "Status" ? "&sort_by=suspicious_emails.status&sort_direction=" + toString(sortBy.VAL) : "&sort_by=suspicious_emails.created_at&sort_direction=" + toString(sortBy.VAL)
        )
    );
  return Rest.$$fetch("/dashboard/suspicious_emails?" + (page + (query$1 + sortBy$1)), "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
