// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function statusFromString(string) {
  switch (string) {
    case "approved" :
        return "Approved";
    case "blocked" :
        return "Blocked";
    case "pending" :
        return "Pending";
    default:
      return PervasivesU.failwith("Unknown status");
  }
}

function statusToString(status) {
  switch (status) {
    case "Pending" :
        return "pending";
    case "Approved" :
        return "approved";
    case "Blocked" :
        return "blocked";
    
  }
}

function fromJs(js) {
  return {
          id: js.id,
          email: js.email,
          status: js.status,
          isValid: js.isValid,
          invalidAttempts: js.invalidAttempts,
          blockedAt: js.blockedAt,
          timeoutAt: js.timeoutAt,
          createdAt: js.createdAt,
          updatedAt: js.updatedAt
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              status: field.required("status", Json_Decode$JsonCombinators.string),
              isValid: field.required("isValid", Json_Decode$JsonCombinators.bool),
              invalidAttempts: field.required("invalidAttempts", Json_Decode$JsonCombinators.$$int),
              blockedAt: Js_null_undefined.fromOption(field.required("blockedAt", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))),
              timeoutAt: Js_null_undefined.fromOption(field.required("timeoutAt", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var Edit = {
  fromJs: fromJs,
  decoder: decoder,
  fromJson: fromJson
};

function fromJs$1(js) {
  return {
          id: js.id,
          email: js.email,
          status: js.status,
          isValid: js.isValid,
          invalidAttempts: js.invalidAttempts,
          createdAt: js.createdAt
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              status: field.required("status", Json_Decode$JsonCombinators.string),
              isValid: field.required("isValid", Json_Decode$JsonCombinators.bool),
              invalidAttempts: field.required("invalidAttempts", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string)
            };
    });

var Dashboard = {
  fromJs: fromJs$1,
  decoder: decoder$1
};

export {
  statusFromString ,
  statusToString ,
  fromJs ,
  decoder ,
  fromJson ,
  Edit ,
  Dashboard ,
}
/* decoder Not a pure module */
